import HomePage from './views/homePage';
import BecomeSeller from './views/becomeSeller';
import AboutPage from './views/aboutPage';
import LocationPage from './views/locationPage';
import JobsPage from './views/jobsPage';
import CookiesPage from './views/cookiesPage';
import OrderPage from './views/OrderPage';
import AccountAddressPage from './views/AccountAddressPage';
import AccountEditPage from './views/AccountEditPage';
import AllOrderPage from './views/AllOrderPage';
import PrivacyPolicyPage from './views/privacyPolicy';
import ConditionPage from './views/ConditionPage';
import ContactPage from './views/contactPage';
import AuthPage from './views/AuthPage';
import CatalogPage from './views/CatalogPage';
import CartPage from './views/CartPage';
import ArticlePage from './views/ArticlePage';
import ArticleDetailsPage from './views/ArticleDetailPage';
import DesignCollection from './views/DesignCollection';
import FQpage from './views/FQpage';

import { API_ENDPOINT } from './constants';
import becomeSeller from './views/becomeSeller';
import privacyPolicy from './views/privacyPolicy';
import AccountPage from './views/AccountPage';
import CheckoutPage from './views/CheckoutPage';
import AccountShippingAddressPage from './views/AccountShippingAddressPage';
import AccountBillingAddressPage from './views/AccountBillingAddressPage';

import OrderDetailPage from './views/OrderDetailPage';
import categorySellerPage from './views/categorySellerPage';
import ForgotPasswordFage from './views/ForgotPasswordPage';
import ResetPasswordPage from './views/ResetPasswordPage';
import AboutMoviePage from './views/AboutMoviePage';
import CareAdvicePage from './views/CareAdvicePage';
import Job2Page from './views/Job2Page';
import FriendsAndFamilyPage from './views/friendsAndFamilyPage';
import FSCPage from './views/FSCPage';
import GeneralConditionsPage from './views/generalConditionsPage';
import KlarnaConfirmationPage from './views/KlarnaConfirmationPage';
import DynamicPage from './views/dynamicPage';
import ReturnGoodsPage from './views/ReturnGoodsPage';
import ComplaintPage from './views/ComplaintPage';
import WishlistPage from './views/WishlistPage';
import AccountChangePasswordPage from './views/AccountChangePasswordPage';
import AccountPreviewPage from './views/AccountPreviewPage';
import UnsubscribePage from './views/UnsubscribePage';
import ProductView360Page from './views/ProductView360Page';
import AccountTermsOfPaymentPage from './views/AccountTermsOfPaymentPage';
import RetrackPage from './views/RetrackPage';
import AccountStockPage from './views/AccountStockPage';
import AccountProductDataPage from './views/AccountProductDataPage';
import StedgerPage from './views/StedgerPage';

const linkTranslates = {
  en: {
    '/': '/en',
    '/account': '/en/account',
    '/vara-skotselrad': '/en/care-advice',
    '/forgot-password': '/en/forgot-password',
    '/unsubscribe/:uid': '/en/unsubscribe/:uid',
    '/reset-password/:uid/:code': '/en/reset-password/:uid/:code',
    '/account-options': '/en/account-options',
    '/bli-aterforsaljare': '/en/become-seller',
    '/om-oss': '/en/about',
    '/om-oss-mov': '/en/about-mov',
    '/hitta-aterforsaljare': '/en/location',
    '/account/orders': '/en/account/orders',
    '/account/orders/:orderNumber': '/en/account/orders/:orderNumber',
    '/account/complaint': '/en/account/complaint',
    '/account/wishlist': '/en/account/wishlist',
    '/account/return-goods': '/en/account/return-goods',
    '/account/edit-address': '/en/account/edit-address',
    '/account/edit-address/shipping': '/en/account/edit-address/shipping',
    '/account/edit-address/billing': '/en/account/edit-address/billing',
    '/account/edit': '/en/account/edit',
    '/account/preview': '/en/account/preview',
    '/account/change-password': '/en/account/change-password',
    '/account/stock': '/en/account/stock',
    '/account/product-data': '/en/account/product-data',
    '/account/invoices': '/en/account/invoices',
    '/cookies': '/en/cookies',
    '/page/:alias': '/en/page/:alias',
    '/personuppgiftspolicy': '/en/privacy-policy',
    '/kopvillkor': '/en/condition',
    '/jobba-hos-oss': '/en/jobs',
    '/kategorisaljare': '/en/category-seller',
    '/job2/:adds': '/en/job2/:adds',
    '/friends-and-family': '/en/friends-and-family',
    '/fsc': '/en/fsc',
    '/retrack': '/en/retrack',

    '/faq': '/en/faq',
    '/cart': '/en/cart',
    '/stedger': '/en/stedger',

    '/checkout': '/en/checkout',
    '/checkout/completed': '/en/checkout/completed',
    '/design-collection': '/en/design-collection',

    '/catalog': '/en/catalog',
    '/kontakt': '/en/contact',
    // '/products/:alias': '/en/products/:alias',
    '/products/:sku/:alias': '/en/products/:sku/:alias',
    '/search/:search': '/en/search/:search',
    '/allmanna-villkor': '/en/general-conditions',
    '/klarna/confirmation': '/en/klarna/confirmation',
    '/products/360-view/:orientation/:sku':
      '/en/products/360-view/:orientation/:sku',
    '/account/terms-of-payment': '/en/account/terms-of-payment',
    '*': '/en/*',
  },
};

function parseJSON(response) {
  return response.json().then((json) => {
    return {
      result: json,
      status: response.status,
    };
  });
}

export const routes = [
  {
    path: '/',
    generateSeoTags: (data, lang) => {
      return {
        title: 'Hem'.translate(lang) + ' - Venture design'.translate(lang),
        description: ''.translate(lang),
        // 'og:image': 'http://localhost:4000/uploads/share.png'
      };
    },
    component: HomePage,
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        let authToken = null;

        if (typeof window !== 'undefined') {
          authToken = `Bearer ${localStorage.getItem('authToken')}`;
        }

        console.log(match);
        return fetchFunction(API_ENDPOINT + '/home/slides', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
        })
          .then(parseJSON)
          .then(({ result, status }) => {
            console.log(result);
            if (status >= 200 && status < 300) {
              return {
                slides: result,
              };
            }
          });
      },

      (fetchFunction, lang, match, query) => {
        let authToken = null;

        if (typeof window !== 'undefined') {
          authToken = `Bearer ${localStorage.getItem('authToken')}`;
        }

        console.log(match);
        return fetchFunction(API_ENDPOINT + '/home/sections', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
        })
          .then(parseJSON)
          .then(({ result, status }) => {
            console.log(result);
            if (status >= 200 && status < 300) {
              return result;
            }
          });
      },
      (fetchFunction, lang, match, query) => {
        let authToken = null;

        if (typeof window !== 'undefined') {
          authToken = `Bearer ${localStorage.getItem('authToken')}`;
        }

        console.log(match);
        return fetchFunction(API_ENDPOINT + '/categories/home', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
        })
          .then(parseJSON)
          .then(({ result, status }) => {
            console.log(result);
            if (status >= 200 && status < 300) {
              return {
                categories: result,
              };
            }
          });
      },

      (fetchFunction, lang, match, query) => {
        let authToken = null;

        if (typeof window !== 'undefined') {
          authToken = localStorage.getItem('authToken')
            ? `Bearer ${localStorage.getItem('authToken')}`
            : localStorage.getItem('guestToken')
              ? `Guest ${localStorage.getItem('guestToken')}`
              : null;
        }

        console.log(match);
        return fetchFunction(API_ENDPOINT + '/products/home', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
        })
          .then(parseJSON)
          .then(({ result, status }) => {
            console.log(result);
            if (status >= 200 && status < 300) {
              return {
                products: result,
              };
            }
          });
      },
      (fetchFunction, lang, match, query) => {
        let authToken = null;

        if (typeof window !== 'undefined') {
          authToken = localStorage.getItem('authToken')
            ? `Bearer ${localStorage.getItem('authToken')}`
            : localStorage.getItem('guestToken')
              ? `Guest ${localStorage.getItem('guestToken')}`
              : null;
        }

        console.log(match);
        return fetchFunction(API_ENDPOINT + '/products/home?fsc=true', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
        })
          .then(parseJSON)
          .then(({ result, status }) => {
            console.log(result);
            if (status >= 200 && status < 300) {
              return {
                fscProducts: result,
              };
            }
          });
      },
    ],
  },
  {
    path: '/bli-aterforsaljare',
    generateSeoTags: (data, lang) => {
      return {
        title:
          'Bli återförsäljare'.translate(lang) +
          ' - Venture design'.translate(lang),
        description: ''.translate(lang),
        // 'og:image': 'http://localhost:4000/uploads/share.png'
      };
    },
    component: BecomeSeller,
    loadData: [],
  },
  {
    path: '/friends-and-family',
    generateSeoTags: (data, lang) => {
      return {
        title:
          'Friends & Family'.translate(lang) +
          ' - Venture design'.translate(lang),
        description: ''.translate(lang),
        // 'og:image': 'http://localhost:4000/uploads/share.png'
      };
    },
    component: FriendsAndFamilyPage,
    loadData: [],
    loadDataWithQuery: [],
  },
  {
    path: '/fsc',
    generateSeoTags: (data, lang) => {
      return {
        title: 'FSC'.translate(lang) + ' - Venture design'.translate(lang),
        description: ''.translate(lang),
        // 'og:image': 'http://localhost:4000/uploads/share.png'
      };
    },
    component: FSCPage,
    loadData: [],
    loadDataWithQuery: [],
  },
  {
    path: '/klarna/confirmation',
    generateSeoTags: (data, lang) => {
      return {
        title:
          'Confirmation'.translate(lang) + ' - Venture design'.translate(lang),
        description: ''.translate(lang),
        // 'og:image': 'http://localhost:4000/uploads/share.png'
      };
    },
    component: KlarnaConfirmationPage,
    loadData: [],
    loadDataWithQuery: [],
  },
  {
    path: '/retrack',
    generateSeoTags: (data, lang) => {
      return {
        title: 'Retrack'.translate(lang) + ' - Venture design'.translate(lang),
        description: ''.translate(lang),
        // 'og:image': 'http://localhost:4000/uploads/share.png'
      };
    },
    component: RetrackPage,
    loadData: [],
    loadDataWithQuery: [],
  },

  {
    path: '/om-oss',
    generateSeoTags: (data, lang) => {
      return {
        title: 'Om oss'.translate(lang) + ' - Venture design'.translate(lang),
        description: ''.translate(lang),
        // 'og:image': 'http://localhost:4000/uploads/share.png'
      };
    },
    component: AboutPage,
    loadData: [],
  },
  {
    path: '/faq',
    generateSeoTags: (data, lang) => {
      return {
        title: 'FAQ'.translate(lang) + ' - Venture design'.translate(lang),
        description: ''.translate(lang),
        // 'og:image': 'http://localhost:4000/uploads/share.png'
      };
    },
    component: FQpage,
    loadData: [],
  },

  {
    path: '/job2/:adds',
    generateSeoTags: (data, lang) => {
      return {
        title:
          Object.translate(data, 'items.headertext', lang) + ' - Venture design'.translate(lang),
        description: ''.translate(lang),
        // 'og:image': 'http://localhost:4000/uploads/share.png'
      };
    },
    component: Job2Page,
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        let authToken = null;

        if (typeof window !== 'undefined') {
          authToken = `Bearer ${localStorage.getItem('authToken')}`;
        }

        console.log(match);
        return fetchFunction(API_ENDPOINT + '/adds/' + match.params.adds, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        })
          .then(parseJSON)
          .then(({ result, status }) => {
            console.log(result);

            if (status >= 200 && status < 300) {
              return { items: result.response };
            }
          });
      },
    ],
  },

  {
    path: '/hitta-aterforsaljare',
    generateSeoTags: (data, lang) => {
      return {
        title:
          'Återförsäljare'.translate(lang) +
          ' - Venture design'.translate(lang),
        description: ''.translate(lang),
        // 'og:image': 'http://localhost:4000/uploads/share.png'
      };
    },
    component: LocationPage,
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        let authToken = null;

        if (typeof window !== 'undefined') {
          authToken = `Bearer ${localStorage.getItem('authToken')}`;
        }

        console.log(match);
        return fetchFunction(API_ENDPOINT + '/locations', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
        })
          .then(parseJSON)
          .then(({ result, status }) => {
            console.log(result);
            if (status >= 200 && status < 300) {
              return { items: result };
            }
          });
      },
    ],
  },
  {
    path: '/cookies',
    generateSeoTags: (data, lang) => {
      return {
        title: 'Cookies'.translate(lang) + ' - Venture design'.translate(lang),
        description: ''.translate(lang),
        // 'og:image': 'http://localhost:4000/uploads/share.png'
      };
    },
    component: CookiesPage,
    loadData: [],
    loadDataWithQuery: [
     (fetchFunction, lang, match, query, pathname) => {
        let authToken = null;

        if (typeof window !== 'undefined') {
          authToken = `Bearer ${localStorage.getItem('authToken')}`;
        }

        return fetchFunction(API_ENDPOINT + '/page/cookies', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        })
          .then(parseJSON)
          .then(({ result, status }) => {
            console.log(result);
            if (status >= 200 && status < 300) {
              return {
                ...result,
              };
            }
          })
          .catch(() => {
            return {};
          });
      },
    ]
  },
  {
    path: '/page/:alias',
    generateSeoTags: (data, lang) => {
      return {
        title:
          Object.translate(data, 'title', lang) +
          ' - Venture design'.translate(lang),
        description: ''.translate(lang),
        // 'og:image': 'http://localhost:4000/uploads/share.png'
      };
    },
    component: DynamicPage,
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query, pathname) => {
        let authToken = null;

        if (typeof window !== 'undefined') {
          authToken = `Bearer ${localStorage.getItem('authToken')}`;
        }

        return fetchFunction(API_ENDPOINT + '/page/' + match.params.alias, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        })
          .then(parseJSON)
          .then(({ result, status }) => {
            console.log(result);
            if (status >= 200 && status < 300) {
              return {
                ...result,
              };
            }
          })
          .catch(() => {
            return {};
          });
      },
    ],
  },
  {
    path: '/stedger',
    generateSeoTags: (data, lang) => {
      return {
        title:
          
          'Stedger - Venture design'.translate(lang),
        description: ''.translate(lang),
        // 'og:image': 'http://localhost:4000/uploads/share.png'
      };
    },
    component: StedgerPage,
    loadData: [],
    loadDataWithQuery: [
      
    ],
  },


  {
    path: '/allmanna-villkor',
    generateSeoTags: (data, lang) => {
      return {
        title:
          'Allmänna villkor'.translate(lang) +
          ' - Venture design'.translate(lang),
        description: ''.translate(lang),
        // 'og:image': 'http://localhost:4000/uploads/share.png'
      };
    },
    component: GeneralConditionsPage,
    loadData: [],
  },

  {
    path: '/forgot-password',
    generateSeoTags: (data, lang) => {
      return {
        title:
          'Mitt konto'.translate(lang) + ' - Venture design'.translate(lang),
        description: ''.translate(lang),
        // 'og:image': 'http://localhost:4000/uploads/share.png'
      };
    },
    component: ForgotPasswordFage,
    loadData: [],
    loadDataWithQuery: [],
  },
  {
    path: '/reset-password/:uid/:code',
    generateSeoTags: (data, lang) => {
      return {
        title:
          'Mitt konto'.translate(lang) + ' - Venture design'.translate(lang),
        description: ''.translate(lang),
        // 'og:image': 'http://localhost:4000/uploads/share.png'
      };
    },
    component: ResetPasswordPage,
    loadData: [],
    loadDataWithQuery: [],
  },
  {
    path: '/unsubscribe/:uid',
    generateSeoTags: (data, lang) => {
      return {
        title:
          'Mitt konto'.translate(lang) + ' - Venture design'.translate(lang),
        description: ''.translate(lang),
        // 'og:image': 'http://localhost:4000/uploads/share.png'
      };
    },
    component: UnsubscribePage,
    loadData: [],
    loadDataWithQuery: [],
  },

  {
    path: '/om-oss-mov',
    generateSeoTags: (data, lang) => {
      return {
        title:
          'Mitt konto'.translate(lang) + ' - Venture design'.translate(lang),
        description: ''.translate(lang),
        // 'og:image': 'http://localhost:4000/uploads/share.png'
      };
    },
    component: AboutMoviePage,
    loadData: [],
    loadDataWithQuery: [],
  },

  {
    path: '/vara-skotselrad',
    generateSeoTags: (data, lang) => {
      return {
        title:
          'VÅRA SKÖTSELRÅD'.translate(lang) +
          ' - Venture design'.translate(lang),
        description: ''.translate(lang),
        // 'og:image': 'http://localhost:4000/uploads/share.png'
      };
    },
    component: CareAdvicePage,
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        let authToken = null;

        if (typeof window !== 'undefined') {
          authToken = `Bearer ${localStorage.getItem('authToken')}`;
        }

        console.log(match);
        return fetchFunction(API_ENDPOINT + '/care-advice', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
        })
          .then(parseJSON)
          .then(({ result, status }) => {
            console.log(result);
            if (status >= 200 && status < 300) {
              return {
                items: result,
              };
            }
          });
      },
    ],
  },

  {
    path: '/account/orders',
    loginNeeded: true,
    preAuthComponent: AuthPage,
    generateSeoTags: (data, lang) => {
      return {
        title:
          'Mitt konto'.translate(lang) + ' - Venture design'.translate(lang),
        description: ''.translate(lang),
        // 'og:image': 'http://localhost:4000/uploads/share.png'
      };
    },
    component: OrderPage,
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        let authToken = null;

        if (typeof window !== 'undefined') {
          authToken = `Bearer ${localStorage.getItem('authToken')}`;
        }

        console.log(match);
        return fetchFunction(API_ENDPOINT + '/account/orders', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
        })
          .then(parseJSON)
          .then(({ result, status }) => {
            console.log(result);
            if (status >= 200 && status < 300) {
              return { items: result };
            }
          });
      },
    ],
  },
  {
    path: '/account/orders/:orderNumber',
    loginNeeded: true,
    preAuthComponent: AuthPage,
    generateSeoTags: (data, lang) => {
      return {
        title:
          'Mitt konto'.translate(lang) + ' - Venture design'.translate(lang),
        description: ''.translate(lang),
        // 'og:image': 'http://localhost:4000/uploads/share.png'
      };
    },
    component: OrderDetailPage,
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        let authToken = null;

        if (typeof window !== 'undefined') {
          authToken = `Bearer ${localStorage.getItem('authToken')}`;
        }

        console.log(match);
        return fetchFunction(
          API_ENDPOINT + '/account/orders/' + match.params.orderNumber,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: authToken,
            },
          },
        )
          .then(parseJSON)
          .then(({ result, status }) => {
            console.log(result);
            if (status >= 200 && status < 300) {
              return { order: result };
            }
          });
      },
    ],
  },
  {
    path: '/account/edit-address',
    loginNeeded: true,
    preAuthComponent: AuthPage,

    generateSeoTags: (data, lang) => {
      return {
        title:
          'Mitt konto'.translate(lang) + ' - Venture design'.translate(lang),
        description: ''.translate(lang),
        // 'og:image': 'http://localhost:4000/uploads/share.png'
      };
    },
    component: AccountAddressPage,
    loadData: [],
  },
  {
    path: '/account/edit-address/shipping',
    loginNeeded: true,
    preAuthComponent: AuthPage,

    generateSeoTags: (data, lang) => {
      return {
        title:
          'Mitt konto'.translate(lang) + ' - Venture design'.translate(lang),
        description: ''.translate(lang),
        // 'og:image': 'http://localhost:4000/uploads/share.png'
      };
    },
    component: AccountShippingAddressPage,
    loadData: [],
  },
  {
    path: '/account/edit-address/billing',
    loginNeeded: true,
    preAuthComponent: AuthPage,

    generateSeoTags: (data, lang) => {
      return {
        title:
          'Mitt konto'.translate(lang) + ' - Venture design'.translate(lang),
        description: ''.translate(lang),
        // 'og:image': 'http://localhost:4000/uploads/share.png'
      };
    },
    component: AccountBillingAddressPage,
    loadData: [],
  },

  {
    path: '/account',
    loginNeeded: true,
    preAuthComponent: AuthPage,
    generateSeoTags: (data, lang) => {
      return {
        title:
          'Mitt konto'.translate(lang) + ' - Venture design'.translate(lang),
        description: ''.translate(lang),
        // 'og:image': 'http://localhost:4000/uploads/share.png'
      };
    },
    component: AccountPage,
    loadData: [],
  },
  {
    path: '/account/wishlist',
    loginNeeded: true,
    preAuthComponent: AuthPage,
    generateSeoTags: (data, lang) => {
      return {
        title:
          'Mitt konto'.translate(lang) + ' - Venture design'.translate(lang),
        description: ''.translate(lang),
        // 'og:image': 'http://localhost:4000/uploads/share.png'
      };
    },
    component: WishlistPage,
    loadData: [],
  },

  {
    path: '/account/complaint',
    loginNeeded: true,
    preAuthComponent: AuthPage,
    generateSeoTags: (data, lang) => {
      return {
        title:
          'Mitt konto'.translate(lang) + ' - Venture design'.translate(lang),
        description: ''.translate(lang),
        // 'og:image': 'http://localhost:4000/uploads/share.png'
      };
    },
    component: ComplaintPage,
    loadData: [],
  },

  {
    path: '/account/return-goods',
    loginNeeded: true,
    preAuthComponent: AuthPage,
    generateSeoTags: (data, lang) => {
      return {
        title:
          'Mitt konto'.translate(lang) + ' - Venture design'.translate(lang),
        description: ''.translate(lang),
        // 'og:image': 'http://localhost:4000/uploads/share.png'
      };
    },
    component: ReturnGoodsPage,
    loadData: [],
  },
  {
    path: '/account/change-password',
    loginNeeded: true,
    preAuthComponent: AuthPage,
    generateSeoTags: (data, lang) => {
      return {
        title:
          'Mitt konto'.translate(lang) + ' - Venture design'.translate(lang),
        description: ''.translate(lang),
        // 'og:image': 'http://localhost:4000/uploads/share.png'
      };
    },
    component: AccountChangePasswordPage,
    loadData: [],
  },
  {
    path: '/account/stock',
    loginNeeded: true,
    preAuthComponent: AuthPage,
    generateSeoTags: (data, lang) => {
      return {
        title:
          'Mitt konto'.translate(lang) + ' - Venture design'.translate(lang),
        description: ''.translate(lang),
        // 'og:image': 'http://localhost:4000/uploads/share.png'
      };
    },
    component: AccountStockPage,
    loadData: [],
  },
  {
    path: '/account/product-data',
    loginNeeded: true,
    preAuthComponent: AuthPage,
    generateSeoTags: (data, lang) => {
      return {
        title:
          'Mitt konto'.translate(lang) + ' - Venture design'.translate(lang),
        description: ''.translate(lang),
        // 'og:image': 'http://localhost:4000/uploads/share.png'
      };
    },
    component: AccountProductDataPage,
    loadData: [],
  },

  {
    path: '/account/preview',
    loginNeeded: true,
    preAuthComponent: AuthPage,
    generateSeoTags: (data, lang) => {
      return {
        title:
          'Mitt konto'.translate(lang) + ' - Venture design'.translate(lang),
        description: ''.translate(lang),
        // 'og:image': 'http://localhost:4000/uploads/share.png'
      };
    },
    component: AccountPreviewPage,
    loadData: [],
  },
  {
    path: '/account/terms-of-payment',
    loginNeeded: true,
    preAuthComponent: AuthPage,
    generateSeoTags: (data, lang) => {
      return {
        title:
          'Mitt konto'.translate(lang) + ' - Venture design'.translate(lang),
        description: ''.translate(lang),
        // 'og:image': 'http://localhost:4000/uploads/share.png'
      };
    },
    component: AccountTermsOfPaymentPage,
    loadData: [],
  },

  {
    path: '/account/edit',
    loginNeeded: true,
    preAuthComponent: AuthPage,
    generateSeoTags: (data, lang) => {
      return {
        title:
          'Mitt konto'.translate(lang) + ' - Venture design'.translate(lang),
        description: ''.translate(lang),
        // 'og:image': 'http://localhost:4000/uploads/share.png'
      };
    },
    component: AccountEditPage,
    loadData: [],
  },
  {
    path: '/account/invoices',
    loginNeeded: true,
    preAuthComponent: AuthPage,
    generateSeoTags: (data, lang) => {
      return {
        title:
          'Mitt konto'.translate(lang) + ' - Venture design'.translate(lang),
        description: ''.translate(lang),
        // 'og:image': 'http://localhost:4000/uploads/share.png'
      };
    },
    component: AllOrderPage,
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        let authToken = null;

        if (typeof window !== 'undefined') {
          authToken = `Bearer ${localStorage.getItem('authToken')}`;
        }

        console.log(match);
        return fetchFunction(API_ENDPOINT + '/account/invoices', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
        })
          .then(parseJSON)
          .then(({ result, status }) => {
            console.log(result);
            if (status >= 200 && status < 300) {
              return result;
            }
          });
      },
    ],
  },

  {
    path: '/personuppgiftspolicy',
    generateSeoTags: (data, lang) => {
      return {
        title:
          'Personuppgiftspolicy'.translate(lang) +
          ' - Venture design'.translate(lang),
        description: ''.translate(lang),
        // 'og:image': 'http://localhost:4000/uploads/share.png'
      };
    },
    component: PrivacyPolicyPage,
    loadData: [],
  },
  {
    path: '/kopvillkor',
    generateSeoTags: (data, lang) => {
      return {
        title:
          'Köpvillkor'.translate(lang) + ' - Venture design'.translate(lang),
        description: ''.translate(lang),
        // 'og:image': 'http://localhost:4000/uploads/share.png'
      };
    },
    component: ConditionPage,
    loadData: [],
  },
  {
    path: '/jobba-hos-oss',
    generateSeoTags: (data, lang) => {
      return {
        title:
          'Venture Design'.translate(lang) +
          ' - Venture design'.translate(lang),
        description: ''.translate(lang),
        // 'og:image': 'http://localhost:4000/uploads/share.png'
      };
    },
    component: JobsPage,
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query, pathname) => {
        let authToken = null;

        if (typeof window !== 'undefined') {
          authToken = `Bearer ${localStorage.getItem('authToken')}`;
        }

        return fetchFunction(API_ENDPOINT + '/adds', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        })
          .then(parseJSON)
          .then(({ result, status }) => {
            console.log(result);
            if (status >= 200 && status < 300) {
              return {
                adds: result.adds,
              };
            }
          })
          .catch(() => {
            return {};
          });
      },
    ],
  },
  {
    path: '/cart',
    generateSeoTags: (data, lang) => {
      return {
        title: 'Cart'.translate(lang) + ' - Venture design'.translate(lang),
        description: ''.translate(lang),
        // 'og:image': 'http://localhost:4000/uploads/share.png'
      };
    },
    component: CartPage,
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query, pathname) => {
        let authToken = null;

        if (typeof window !== 'undefined') {
          authToken = localStorage.getItem('authToken')
            ? `Bearer ${localStorage.getItem('authToken')}`
            : localStorage.getItem('guestToken')
              ? `Guest ${localStorage.getItem('guestToken')}`
              : null;
        }

        return fetchFunction(API_ENDPOINT + '/cart', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
        })
          .then(parseJSON)
          .then(({ result, status }) => {
            console.log('reyultat: ', result);
            if (status >= 200 && status < 300) {
              return {
                items: result.items,
                total: result.total,
                subtotal: result.subtotal,
                vat: result.vat,
                currency: result.currency,
                shippingCost: result.shippingCost,
                servicePointRequired: result.servicePointRequired,
                totalWithoutDiscount: result.totalWithoutDiscount,
                subtotalWithoutDiscount: result.subtotalWithoutDiscount,  
                };
            }
          })
          .catch(() => {
            return {};
          });
      },
      (fetchFunction, lang, match, query, pathname) => {
        let authToken = null;

        if (typeof window !== 'undefined') {
          authToken = localStorage.getItem('authToken')
            ? `Bearer ${localStorage.getItem('authToken')}`
            : localStorage.getItem('guestToken')
              ? `Guest ${localStorage.getItem('guestToken')}`
              : null;
        }

        return fetchFunction(API_ENDPOINT + '/cart/discount/code', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
        })
          .then(parseJSON)
          .then(({ result, status }) => {
            console.log(result);
            if (status >= 200 && status < 300) {
              return {
                usedDiscountCode: result.code,
                discountValue: result.discount,
              };
            } else {
              if (result.expired) {
                return {
                  discountCodeExpired: true,
                };
              }
            }
          })
          .catch(() => {
            return {};
          });
      },
    ],
  },
  {
    path: '/checkout',
    generateSeoTags: (data, lang) => {
      return {
        title: 'Checkout'.translate(lang) + ' - Venture design'.translate(lang),
        description: ''.translate(lang),
        // 'og:image': 'http://localhost:4000/uploads/share.png'
      };
    },
    component: CheckoutPage,
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query, pathname) => {
        let authToken = null;

        if (typeof window !== 'undefined') {
          authToken = localStorage.getItem('authToken')
            ? `Bearer ${localStorage.getItem('authToken')}`
            : localStorage.getItem('guestToken')
              ? `Guest ${localStorage.getItem('guestToken')}`
              : null;
        }

        return fetchFunction(API_ENDPOINT + '/cart', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
        })
          .then(parseJSON)
          .then(({ result, status }) => {
            console.log(result);
            if (status >= 200 && status < 300) {
              return {
                items: result.items,
                total: result.total,
                subtotal: result.subtotal,
                vat: result.vat,
                currency: result.currency,
                shippingCost: result.shippingCost,
                servicePointRequired: result.servicePointRequired,
                totalWithoutDiscount: result.totalWithoutDiscount,
                subtotalWithoutDiscount: result.subtotalWithoutDiscount,  
              };
            }
          })
          .catch(() => {
            return {};
          });
      },
      (fetchFunction, lang, match, query, pathname) => {
        let authToken = null;

        if (typeof window !== 'undefined') {
          authToken = localStorage.getItem('authToken')
            ? `Bearer ${localStorage.getItem('authToken')}`
            : localStorage.getItem('guestToken')
              ? `Guest ${localStorage.getItem('guestToken')}`
              : null;
        }

        return fetchFunction(API_ENDPOINT + '/cart/discount/code', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
        })
          .then(parseJSON)
          .then(({ result, status }) => {
            console.log(result);
            if (status >= 200 && status < 300) {
              return {
                usedDiscountCode: result.code,
                discountValue: result.discount,
              };
            } else {
              if (result.expired) {
                return {
                  discountCodeExpired: true,
                };
              }
            }
          })
          .catch(() => {
            return {};
          });
      },
    ],
  },
  {
    path: '/checkout/completed',
    generateSeoTags: (data, lang) => {
      return {
        title: 'Checkout'.translate(lang) + ' - Venture design'.translate(lang),
        description: ''.translate(lang),
        // 'og:image': 'http://localhost:4000/uploads/share.png'
      };
    },
    component: CheckoutPage,
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query, pathname) => {
        let authToken = null;

        if (typeof window !== 'undefined') {
          authToken = localStorage.getItem('authToken')
            ? `Bearer ${localStorage.getItem('authToken')}`
            : localStorage.getItem('guestToken')
              ? `Guest ${localStorage.getItem('guestToken')}`
              : null;
        }

        return fetchFunction(API_ENDPOINT + '/cart', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
        })
          .then(parseJSON)
          .then(({ result, status }) => {
            console.log(result);
            if (status >= 200 && status < 300) {
              return {
                items: result.items,
                total: result.total,
                subtotal: result.subtotal,
                vat: result.vat,
                currency: result.currency,
              };
            }
          })
          .catch(() => {
            return {};
          });
      },
    ],
  },

  {
    path: '/catalog',
    generateSeoTags: (data, lang) => {
      return {
        title:
          'Våra produktkataloger'.translate(lang) +
          ' - Venture design'.translate(lang),
        description: ''.translate(lang),
        // 'og:image': 'http://localhost:4000/uploads/share.png'
      };
    },
    component: CatalogPage,
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        let authToken = null;

        if (typeof window !== 'undefined') {
          authToken = `Bearer ${localStorage.getItem('authToken')}`;
        }

        console.log(match);
        return fetchFunction(API_ENDPOINT + '/catalogs', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
        })
          .then(parseJSON)
          .then(({ result, status }) => {
            console.log(result);
            if (status >= 200 && status < 300) {
              return {
                catalogs: result,
              };
            }
          });
      },
    ],
  },
  {
    path: '/design-collection',
    generateSeoTags: (data, lang) => {
      return {
        title:
          'Design collection'.translate(lang) +
          ' - Venture design'.translate(lang),
        description: ''.translate(lang),
        // 'og:image': 'http://localhost:4000/uploads/share.png'
      };
    },
    component: DesignCollection,
    loadData: [],
  },
  {
    path: '/kontakt',
    generateSeoTags: (data, lang) => {
      return {
        title: 'Kontakt'.translate(lang) + ' - Venture design'.translate(lang),
        description: ''.translate(lang),
        // 'og:image': 'http://localhost:4000/uploads/share.png'
      };
    },
    component: ContactPage,
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        let authToken = null;

        if (typeof window !== 'undefined') {
          authToken = `Bearer ${localStorage.getItem('authToken')}`;
        }

        console.log(match);
        return fetchFunction(API_ENDPOINT + '/team', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
        })
          .then(parseJSON)
          .then(({ result, status }) => {
            console.log(result);
            if (status >= 200 && status < 300) {
              return { items: result };
            }
          });
      },
    ],
  },
  // {
  //     path: "/auth",
  //     generateSeoTags: (data, lang) => {
  //         return {
  //             title: 'Venture Design'.translate(lang) + ' - Venture design'.translate(lang),
  //             description: ''.translate(lang),
  //             // 'og:image': 'http://localhost:4000/uploads/share.png'
  //         }
  //     },
  //     component: AuthPage,
  //     loadData: []
  // },
  // {
  //   path: '/products/:alias',
  //   generateSeoTags: (data, lang) => {
  //     let obj = {
  //       title:
  //         (data.product
  //           ? `${data.product.brand ? data.product.brand + ' ' : ''
  //           }${Object.translate(data.product, 'name', lang)}`
  //           : '') + ' - Venture design'.translate(lang),
  //       description: ''.translate(lang),
  //     };

  //     if (data && data.product && data.product.thumbnail) {
  //       obj['og:image'] = API_ENDPOINT + data.product.thumbnail.thumbnail;
  //     }

  //     return obj;
  //   },
  //   component: ArticlePage,
  //   loadData: [],
  //   loadDataWithQuery: [
  //     (fetchFunction, lang, match, query) => {
  //       let authToken = null;

  //       if (typeof window !== 'undefined') {
  //         authToken = localStorage.getItem('authToken')
  //           ? `Bearer ${localStorage.getItem('authToken')}`
  //           : localStorage.getItem('guestToken')
  //             ? `Guest ${localStorage.getItem('guestToken')}`
  //             : null;
  //       }

  //       console.log(match);
  //       return fetchFunction(
  //         API_ENDPOINT + '/products/' + lang + '/' + match.params.alias,
  //         {
  //           method: 'GET',
  //           headers: {
  //             'Content-Type': 'application/json',
  //             Authorization: authToken,
  //           },
  //         },
  //       )
  //         .then(parseJSON)
  //         .then(({ result, status }) => {
  //           console.log(result);
  //           if (status >= 200 && status < 300) {
  //             return {
  //               product: result,
  //               previewImage:
  //                 result && result.images && result.images.length
  //                   ? result.images[0]
  //                   : null,
  //               quantity:
  //                 result && result.quantityStep ? result.quantityStep : 1,
  //             };
  //           }
  //         })
  //         .catch(() => {
  //           return {};
  //         });
  //     },
  //     (fetchFunction, lang, match, query) => {
  //       let authToken = null;

  //       if (typeof window !== 'undefined') {
  //         authToken = localStorage.getItem('authToken')
  //           ? `Bearer ${localStorage.getItem('authToken')}`
  //           : localStorage.getItem('guestToken')
  //             ? `Guest ${localStorage.getItem('guestToken')}`
  //             : null;
  //       }

  //       console.log(match);
  //       return fetchFunction(
  //         API_ENDPOINT + '/products/similar/' + lang + '/' + match.params.alias,
  //         {
  //           method: 'GET',
  //           headers: {
  //             'Content-Type': 'application/json',
  //             Authorization: authToken,
  //           },
  //         },
  //       )
  //         .then(parseJSON)
  //         .then(({ result, status }) => {
  //           console.log(result);
  //           if (status >= 200 && status < 300) {
  //             return {
  //               similarProducts: result,
  //             };
  //           }
  //         });
  //     },
  //     (fetchFunction, lang, match, query) => {
  //       let authToken = null;

  //       if (typeof window !== 'undefined') {
  //         authToken = localStorage.getItem('authToken')
  //           ? `Bearer ${localStorage.getItem('authToken')}`
  //           : localStorage.getItem('guestToken')
  //             ? `Guest ${localStorage.getItem('guestToken')}`
  //             : null;
  //       }

  //       console.log(match);
  //       return fetchFunction(
  //         API_ENDPOINT + '/products/linked/' + lang + '/' + match.params.alias,
  //         {
  //           method: 'GET',
  //           headers: {
  //             'Content-Type': 'application/json',
  //             Authorization: authToken,
  //           },
  //         },
  //       )
  //         .then(parseJSON)
  //         .then(({ result, status }) => {
  //           console.log(result);
  //           if (status >= 200 && status < 300) {
  //             return {
  //               linkedProducts: result,
  //             };
  //           }
  //         });
  //     },
  //   ],
  // },
  {
    path: '/products/360-view/:orientation/:sku',
    generateSeoTags: (data, lang) => {
      let obj = {
        title:
          (data.product
            ? `${data.product.brand ? data.product.brand + ' ' : ''
            }${Object.translate(data.product, 'name', lang)}`
            : '') + ' - Venture design'.translate(lang),
        description: ''.translate(lang),
      };

      if (data && data.product && data.product.thumbnail) {
        obj['og:image'] = API_ENDPOINT + data.product.thumbnail.thumbnail;
      }

      return obj;
    },
    component: ProductView360Page,
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        let authToken = null;

        if (typeof window !== 'undefined') {
          authToken = localStorage.getItem('authToken')
            ? `Bearer ${localStorage.getItem('authToken')}`
            : localStorage.getItem('guestToken')
              ? `Guest ${localStorage.getItem('guestToken')}`
              : null;
        }

        console.log(match);
        return fetchFunction(
          API_ENDPOINT + '/products/sku/' + match.params.sku,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: authToken,
            },
          },
        )
          .then(parseJSON)
          .then(({ result, status }) => {
            console.log(result);
            if (status >= 200 && status < 300) {
              return {
                product: result,
                previewImage:
                  result && result.images && result.images.length
                    ? result.images[0]
                    : null,
                quantity:
                  result && result.quantityStep ? result.quantityStep : 1,
              };
            }
          })
          .catch(() => {
            return {};
          });
      },
    ],
  },

  {
    path: '/products/:sku/:alias',
    generateSeoTags: (data, lang) => {
      let obj = {
        title:
          (data.product
            ? `${data.product.brand ? data.product.brand + ' ' : ''
            }${Object.translate(data.product, 'name', lang)}`
            : '') + ' - Venture design'.translate(lang),
        description: ''.translate(lang),
      };

      if (data && data.product && data.product.thumbnail) {
        obj['og:image'] = API_ENDPOINT + data.product.thumbnail.thumbnail;
      }

      return obj;
    },
    component: ArticleDetailsPage,
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        let authToken = null;

        if (typeof window !== 'undefined') {
          authToken = localStorage.getItem('authToken')
            ? `Bearer ${localStorage.getItem('authToken')}`
            : localStorage.getItem('guestToken')
              ? `Guest ${localStorage.getItem('guestToken')}`
              : null;
        }

        console.log(match);
        return fetchFunction(
          API_ENDPOINT + '/products/' + lang + '/' + match.params.alias,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: authToken,
            },
          },
        )
          .then(parseJSON)
          .then(({ result, status }) => {
            console.log(result);
            if (status >= 200 && status < 300) {
              return {
                product: result,
                previewImage:
                  result && result.images && result.images.length
                    ? result.images[0]
                    : null,
                quantity:
                  result && result.quantityStep ? result.quantityStep : 1,
              };
            }
          })
          .catch(() => {
            return {};
          });
      },
      (fetchFunction, lang, match, query) => {
        let authToken = null;

        if (typeof window !== 'undefined') {
          authToken = localStorage.getItem('authToken')
            ? `Bearer ${localStorage.getItem('authToken')}`
            : localStorage.getItem('guestToken')
              ? `Guest ${localStorage.getItem('guestToken')}`
              : null;
        }

        console.log(match);
        return fetchFunction(
          API_ENDPOINT + '/products/similar/' + lang + '/' + match.params.alias,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: authToken,
            },
          },
        )
          .then(parseJSON)
          .then(({ result, status }) => {
            console.log(result);
            if (status >= 200 && status < 300) {
              return {
                similarProducts: result,
              };
            }
          });
      },
      (fetchFunction, lang, match, query) => {
        let authToken = null;

        if (typeof window !== 'undefined') {
          authToken = localStorage.getItem('authToken')
            ? `Bearer ${localStorage.getItem('authToken')}`
            : localStorage.getItem('guestToken')
              ? `Guest ${localStorage.getItem('guestToken')}`
              : null;
        }

        console.log(match);
        return fetchFunction(
          API_ENDPOINT + '/products/linked/' + lang + '/' + match.params.alias,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: authToken,
            },
          },
        )
          .then(parseJSON)
          .then(({ result, status }) => {
            console.log(result);
            if (status >= 200 && status < 300) {
              return {
                linkedProducts: result,
              };
            }
          });
      },
    ],
  },

  {
    path: '/search/:search',
    generateSeoTags: (data, lang) => {
      return {
        title:
          'Venture Design'.translate(lang) +
          ' - Venture design'.translate(lang),
        description: ''.translate(lang),
        // 'og:image': 'http://localhost:4000/uploads/share.png'
      };
    },
    component: ArticlePage,
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query, pathname) => {
        return fetchFunction(API_ENDPOINT + '/categories/' + lang + '/get', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            breadcrumb: '',
          }),
        })
          .then(parseJSON)
          .then(({ result, status }) => {
            console.log(result);
            if (status >= 200 && status < 300) {
              return {
                category: result,
              };
            }
          })
          .catch(() => {
            return {};
          });
      },
      (fetchFunction, lang, match, query, pathname) => {
        let authToken = null;

        if (typeof window !== 'undefined') {
          authToken = localStorage.getItem('authToken')
            ? `Bearer ${localStorage.getItem('authToken')}`
            : localStorage.getItem('guestToken')
              ? `Guest ${localStorage.getItem('guestToken')}`
              : null;
        }

        let attrs = [];
        attrs = Object.keys(query)
          .filter((item) => item.indexOf('attrs.') !== -1)
          .map((item) => {
            return {
              key: item.replace('attrs.', ''),
              value: query[item].split('||'),
            };
          });
        return fetchFunction(API_ENDPOINT + '/products', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
          body: JSON.stringify({
            lang: lang,
            page: query.page,
            sortType: query.sortType,
            sortField: query.sortField,
            sort: query.sort,
            filter: query.filter,
            search: match.params.search,
            breadcrumb: '',
            attributes: attrs,
          }),
        })
          .then(parseJSON)
          .then(({ result, status }) => {
            if (status >= 200 && status < 300) {
              return {
                products: result.items,
                __page: result.page,
                total: result.total,
                pageSize: result.pageSize,
                categoryChain: result.categoryChain,
              };
            }
          })
          .catch(() => {
            return {};
          });
      },
    ],
  },

  {
    path: '*',
    generateSeoTags: (data, lang) => {
      return {
        title:
          (data.category && data.category.name
            ? data.category.name[lang]
            : 'Category') + ' - Venture design'.translate(lang),
        description: ''.translate(lang),
        // 'og:image': 'http://localhost:4000/uploads/share.png'
      };
    },
    component: ArticlePage,
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query, pathname) => {
        return fetchFunction(API_ENDPOINT + '/categories/' + lang + '/get', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            breadcrumb: pathname.replace('/produkt-kategori', ''),
          }),
        })
          .then(parseJSON)
          .then(({ result, status }) => {
            console.log(result);
            if (status >= 200 && status < 300) {
              return {
                category: result,
              };
            }
          })
          .catch(() => {
            return {};
          });
      },
      (fetchFunction, lang, match, query, pathname) => {
        let authToken = null;

        if (typeof window !== 'undefined') {
          authToken = localStorage.getItem('authToken')
            ? `Bearer ${localStorage.getItem('authToken')}`
            : localStorage.getItem('guestToken')
              ? `Guest ${localStorage.getItem('guestToken')}`
              : null;
        }

        let attrs = [];
        attrs = Object.keys(query)
          .filter((item) => item.indexOf('attrs.') !== -1)
          .map((item) => {
            return {
              key: item.replace('attrs.', ''),
              value: query[item].split('||'),
            };
          });
        return fetchFunction(API_ENDPOINT + '/products', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
          body: JSON.stringify({
            lang: lang,
            page: query.page,
            sort: query.sort,
            filter: query.filter,
            breadcrumb: pathname.replace('/produkt-kategori', ''),
            attributes: attrs,
          }),
        })
          .then(parseJSON)
          .then(({ result, status }) => {
            if (status >= 200 && status < 300) {
              return {
                products: result.items,
                __page: result.page,
                total: result.total,
                pageSize: result.pageSize,
                categoryChain: result.categoryChain,
              };
            }
          })
          .catch(() => {
            return {};
          });
      },
    ],
    loadDataWithQueryChildren: [
      (fetchFunction, lang, match, query, pathname) => {
        let authToken = null;

        if (typeof window !== 'undefined') {
          authToken = localStorage.getItem('authToken')
            ? `Bearer ${localStorage.getItem('authToken')}`
            : localStorage.getItem('guestToken')
              ? `Guest ${localStorage.getItem('guestToken')}`
              : null;
        }

        console.log(match);
        return fetchFunction(
          API_ENDPOINT + '/products/' + lang + '/' + pathname.replace('/products', ''),
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: authToken,
            },
          },
        )
          .then(parseJSON)
          .then(({ result, status }) => {
            console.log(result);
            if (status >= 200 && status < 300) {
              return {
                product: result,
                previewImage:
                  result && result.images && result.images.length
                    ? result.images[0]
                    : null,
                quantity:
                  result && result.quantityStep ? result.quantityStep : 1,
              };
            }
          })
          .catch(() => {
            return {};
          });
      },
      (fetchFunction, lang, match, query, pathname) => {
        let authToken = null;

        if (typeof window !== 'undefined') {
          authToken = localStorage.getItem('authToken')
            ? `Bearer ${localStorage.getItem('authToken')}`
            : localStorage.getItem('guestToken')
              ? `Guest ${localStorage.getItem('guestToken')}`
              : null;
        }

        console.log(match);
        return fetchFunction(
          API_ENDPOINT + '/products/similar/' + lang + '/' + pathname.replace('/products', ''),
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: authToken,
            },
          },
        )
          .then(parseJSON)
          .then(({ result, status }) => {
            console.log(result);
            if (status >= 200 && status < 300) {
              return {
                similarProducts: result,
              };
            }
          });
      },
      (fetchFunction, lang, match, query, pathname) => {
        let authToken = null;

        if (typeof window !== 'undefined') {
          authToken = localStorage.getItem('authToken')
            ? `Bearer ${localStorage.getItem('authToken')}`
            : localStorage.getItem('guestToken')
              ? `Guest ${localStorage.getItem('guestToken')}`
              : null;
        }

        console.log(match);
        return fetchFunction(
          API_ENDPOINT + '/products/linked/' + lang + '/' + pathname.replace('/products', ''),
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: authToken,
            },
          },
        )
          .then(parseJSON)
          .then(({ result, status }) => {
            console.log(result);
            if (status >= 200 && status < 300) {
              return {
                linkedProducts: result,
              };
            }
          });
      },
    ],
  },
];

export function generateRoutes(r) {
  let newRoutes = [];
  for (let i = 0; i < r.length; i++) {
    newRoutes.push(r[i]);

    newRoutes.push({
      ...r[i],
      path: linkTranslates.en[r[i].path],
    });
  }
  return newRoutes;
}
