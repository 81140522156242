import React, { Component } from 'react';
import Page from '../containers/page';
import Isvg from 'react-inlinesvg';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import miniLogo from '../assets/svg/mini-logo.svg';

import {
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselControl,
} from 'reactstrap';

import Article from '../components/article';
import articleTest from '../assets/images/article-test.jpg';
import a1 from '../assets/images/a1.jpg';
import a2 from '../assets/images/a2.jpg';
import a3 from '../assets/images/a3.jpg';
import s1 from '../assets/images/slider1.jpg';
import s2 from '../assets/images/slider2.jpg';
import s3 from '../assets/images/slider3.jpg';
import s4 from '../assets/images/slider4.jpg';
import s5 from '../assets/images/slider5.jpg';
import s6 from '../assets/images/slider6.jpg';
import s7 from '../assets/images/slider7.jpg';
import { API_ENDPOINT } from '../constants';
import { Link } from 'react-router-dom';
import noImage from '../assets/images/no-image.png';
import pdfIcon from '../assets/svg/pdf.svg';
import view360 from '../assets/images/360.png';

import {
  getSearchParams,
  generateSearchLink,
  generateSearchLinkMultiple,
} from '../helpers/linkHelper';
import moment from 'moment';
import Product360 from '../components/product360';
var striptags = require('striptags');

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
}

class ArticleDetailPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.initialData,
      filters: false,
      tab: 0,
      quantity: 1,
      photoIndex: 0,
      activeIndex: null,
    };
  }

  get = () => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }

    for (let i = 0; i < this.props.loadData.length; i++) {
      this.props.loadData[i](
        window.fetch,
        this.props.lang,
        this.props[0].match,
      ).then((data) => {
        this.setState({
          ...data,
        });
      });
    }

    if (this.props.loadDataWithQuery) {
      for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
        this.props.loadDataWithQuery[i](
          window.fetch,
          this.props.lang,
          this.props[0].match,
          getSearchParams(this.props[0].location.search, {}),
        ).then((data) => {
          this.setState(
            {
              ...data,
              loading: null,
            },
            () => {
              this.props.updateMeta(
                this.props.generateSeoTags(this.state, this.props.lang),
              );
            },
          );
        });
      }
    }

    this.props.updateMeta(
      this.props.generateSeoTags(this.state, this.props.lang),
    );
  };

  componentDidMount() {
    this.get();
    window.addEventListener('resize', this.updateDimensions);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps[0].location.pathname != this.props[0].location.pathname) {
      this.setState(
        {
          similarProducts: null,
          linkedProducts: null,
        },
        () => {
          this.get();
        },
      );
    }

    if (
      (!prevState.product && this.state.product) ||
      (prevState.product &&
        this.state.product &&
        prevState.product.sku != this.state.product.sku)
    ) {
      if (window.gtag) {
        console.log('--------------');
        window.gtag('event', 'view_item', {
          items: [
            {
              id: this.state.product.sku,
              name: Object.translate(
                this.state.product,
                'sortName',
                this.props.lang,
              ),
              list_name: 'Product detail',
              brand: this.state.product.brand,
              category:
                this.state.product.categoryChain &&
                this.state.product.categoryChain.length &&
                this.state.product.categoryChain[
                  this.state.product.categoryChain.length - 1
                ]
                  ? Object.translate(
                      this.state.product.categoryChain[
                        this.state.product.categoryChain.length - 1
                      ],
                      'name',
                      this.props.lang,
                    )
                  : '',
              list_position: 1,
              price: this.state.product.price
                ? this.state.product.price.value
                : null,
            },
          ],
        });
      }
    }
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  onExiting = () => {
    this.animating = true;
  };

  onExited = () => {
    this.animating = false;
  };

  next = () => {
    if (this.animating) return;

    let items =
      this.state.product && this.state.product.careAdvice
        ? this.state.product.careAdvice
        : [];

    const nextIndex =
      this.state.activeIndex === items.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  };

  previous = () => {
    if (this.animating) return;

    let items =
      this.state.product && this.state.product.careAdvice
        ? this.state.product.careAdvice
        : [];

    const nextIndex =
      this.state.activeIndex === 0
        ? items.length - 1
        : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  };

  goToIndex = (newIndex) => {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  };

  render() {
    var settings = {
      dots: false,
      arrows: true,
      infinite: true,
      speed: 500,
      slidesToShow:
        typeof window !== 'undefined' && window.innerWidth < 768 ? 6 : 8,
      slidesToScroll: 8,
      vertical:
        typeof window !== 'undefined' && window.innerWidth < 768 ? false : true,
    };

    var settingsProductsSlider = {
      dots: false,
      arrows: true,
      infinite: true,
      speed: 500,
      slidesToShow:
        typeof window !== 'undefined' && window.innerWidth < 768 ? 2 : 4,
      slidesToScroll: 1,
    };

    let attrGroupsDict = {};
    if (
      this.state.product &&
      this.state.product.attributes &&
      Object.keys(this.state.product.attributes).length
    ) {
      for (var key in this.state.product.attributes) {
        if (
          this.state.product.attributes[key] &&
          this.state.product.attributes[key].group &&
          this.state.product.attributes[key].group.en
        ) {
          attrGroupsDict[this.state.product.attributes[key].group.en] =
            this.state.product.attributes[key].group;
        }
      }
    }

    let groupValues = Object.values(attrGroupsDict);
    let _idx = groupValues.findIndex(
      (item) => item && item.en && item.en.toLowerCase() == 'material',
    );
    if (_idx != -1) {
      groupValues.splice(_idx, 1);
      groupValues = [{ en: 'Material', sv: 'Material' }, ...groupValues];
    }

    let attrGroups = [{ en: '', sv: '' }, ...groupValues];

    for (let i = 0; i < attrGroups.length; i++) {
      if (attrGroups[i].en == 'Material') {
        attrGroups[i].position = 1;
      }
      if (attrGroups[i].en == 'Color') {
        attrGroups[i].position = 0;
      }

      if (attrGroups[i].en == 'Dimensions') {
        attrGroups[i].position = 2;
      }

      if (attrGroups[i].en == 'Other') {
        attrGroups[i].position = 3;
      }
    }

    attrGroups.sort((a, b) => a.position - b.position);

    let quantityStep =
      this.state.product && this.state.product.quantityStep
        ? this.state.product.quantityStep
        : 1;

    return (
      <div>
        {this.state.product && this.state.product.categoryChain ? (
          <div className="breadcrumb-container">
            <Container fluid>
              <Row>
                <Col lg="12">
                  <Link to="/">{'HEM'.translate(this.props.lang)}</Link>
                  {this.state.product.categoryChain.map((item, idx) => {
                    return (
                      <>
                        <span>/</span>
                        <Link
                          to={Object.translate(
                            item,
                            'breadcrumb',
                            this.props.lang,
                          )}
                        >
                          {Object.translate(item, 'name', this.props.lang)}
                        </Link>
                      </>
                    );
                  })}
                </Col>
              </Row>
            </Container>
          </div>
        ) : null}

        {/*
                <div className="breadcrumb-container">
                        <Container fluid>
                            <Row>
                                <Col lg="12">
                                    <a>HEM</a>
                                    <span>/</span>
                                    <a>Innemöbler</a>
                                    <span>/</span>
                                    <a>BORD</a>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                */}

        <section className="article-detail">
          <Container fluid>
            <Row>
              <Col lg="9">
                <div className="article-detail-image">
                  {this.state.product &&
                  this.state.product.images &&
                  typeof window !== 'undefined' &&
                  ((this.state.product.images.length > 6 &&
                    window.innerWidth < 768) ||
                    (this.state.product.images.length >= 8 &&
                      window.innerWidth >= 768)) ? (
                    <div className="v-slider">
                      {this.state.product &&
                      this.state.product.images &&
                      this.state.product.images.length ? (
                        <Slider {...settings}>
                          {this.state.product &&
                          this.state.product.have360Horizontal ? (
                            <div
                              className="slide"
                              onClick={() =>
                                this.setState({
                                  previewImage: '360view',
                                  photoIndex: '360view',
                                })
                              }
                            >
                              <img src={view360} />
                            </div>
                          ) : null}

                          {this.state.product.images.map((item, idx) => {
                            return (
                              <div
                                className="slide"
                                onClick={() =>
                                  this.setState({
                                    previewImage: item,
                                    photoIndex: idx,
                                  })
                                }
                              >
                                <img
                                  src={
                                    API_ENDPOINT.replace('testapi', 'api') +
                                    item.thumbnail
                                  }
                                />
                              </div>
                            );
                          })}
                        </Slider>
                      ) : null}
                    </div>
                  ) : null}

                  {this.state.product &&
                  this.state.product.images &&
                  this.state.product.images.length > 1 &&
                  typeof window !== 'undefined' &&
                  ((this.state.product.images.length < 6 &&
                    window.innerWidth < 768) ||
                    (this.state.product.images.length < 8 &&
                      window.innerWidth >= 768)) ? (
                    <div className="v-slider v-no-slide">
                      {this.state.product &&
                      this.state.product.have360Horizontal ? (
                        <div
                          className="slide"
                          onClick={() =>
                            this.setState({
                              previewImage: '360view',
                              photoIndex: '360view',
                            })
                          }
                        >
                          <img src={view360} />
                        </div>
                      ) : null}

                      {this.state.product.images.map((item, idx) => {
                        return (
                          <div
                            className="slide"
                            onClick={() =>
                              this.setState({
                                previewImage: item,
                                photoIndex: idx,
                              })
                            }
                          >
                            <img
                              src={
                                API_ENDPOINT.replace('testapi', 'api') +
                                item.thumbnail
                              }
                            />
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                  <div className="v-thumb">
                    {this.state.previewImage ? (
                      this.state.previewImage == '360view' ? (
                        <div className="product-360-view">
                          <Product360
                            amount={12}
                            imagePath={`${API_ENDPOINT}/assets/products/360-view/`}
                            fileName={`${this.state.product.sku}/horizontal/{index}.jpg`}
                          />
                          <h6 className="v-thumb-text">
                            Observera att bilderna är vägledande. Nyanser kan
                            skilja sig från verkligheten.
                          </h6>
                        </div>
                      ) : (
                        <div
                          onClick={() => {
                            this.setState({
                              isOpen: this.state.previewImage,
                            });
                            console.log(this.state.photoIndex);
                          }}
                        >
                          <img
                            src={
                              API_ENDPOINT.replace('testapi', 'api') +
                              this.state.previewImage.file
                            }
                          />
                          <h6 className="v-thumb-text">
                            Observera att bilderna är vägledande. Nyanser kan
                            skilja sig från verkligheten.
                          </h6>
                        </div>
                      )
                    ) : null}
                  </div>
                  {this.state.isOpen ? (
                    <Lightbox
                      mainSrc={
                        API_ENDPOINT +
                        (this.state.product.images[this.state.photoIndex] &&
                          this.state.product.images[this.state.photoIndex].file)
                      }
                      nextSrc={
                        API_ENDPOINT +
                        this.state.product.images[
                          this.state.photoIndex + 1 >=
                          this.state.product.images.length
                            ? 0
                            : this.state.photoIndex + 1
                        ].file
                      }
                      prevSrc={
                        API_ENDPOINT +
                        this.state.product.images[
                          this.state.photoIndex - 1 < 0
                            ? this.state.product.images.length - 1
                            : this.state.photoIndex - 1
                        ].file
                      }
                      onCloseRequest={() => this.setState({ isOpen: false })}
                      onMovePrevRequest={() =>
                        this.setState({
                          photoIndex:
                            (this.state.photoIndex +
                              this.state.product.images.length -
                              1) %
                            this.state.product.images.length,
                        })
                      }
                      onMoveNextRequest={() =>
                        this.setState({
                          photoIndex:
                            (this.state.photoIndex + 1) %
                            this.state.product.images.length,
                        })
                      }
                    />
                  ) : null}
                </div>
              </Col>
              <Col lg="3">
                <div className="article-detail-text">
                  {/* <span className="categories">{this.state.product && this.state.product.categories ? this.state.product.categories.map((item, idx) => { return <Link to={Object.translate(item, 'breadcrumb', this.props.lang)}>{Object.translate(item, 'name', this.props.lang)}</Link> }) : null}</span> */}
                  <span className="categories">
                    {this.state.product && this.state.product.brand
                      ? this.state.product.brand
                      : this.state.product && this.state.product.categories
                      ? this.state.product.categories.map((item, idx) => {
                          return (
                            <Link
                              to={Object.translate(
                                item,
                                'breadcrumb',
                                this.props.lang,
                              )}
                            >
                              {Object.translate(item, 'name', this.props.lang)}
                            </Link>
                          );
                        })
                      : null}
                  </span>

                  <h1>
                    {this.state.product
                      ? this.state.product.productFamily
                      : null}{' '}
                    <span>
                      {Object.translate(
                        this.state.product,
                        'name',
                        this.props.lang,
                      )}
                    </span>
                  </h1>
                  <div className="options">
                    <div className="color">
                      <div className="color-type">
                        <h6>{'Färg:'.translate(this.props.lang)} </h6>
                        {this.state.product &&
                        this.state.product.groupProducts &&
                        this.state.product.groupProducts.length ? (
                          <span className="choosen-color">
                            {' '}
                            {this.state.product.groupProducts
                              .map((item) => {
                                return Object.translate(
                                  item,
                                  'attributes.main_colour_1.value',
                                  this.props.lang,
                                )
                                  ? typeof Object.translate(
                                      item,
                                      'attributes.main_colour_1.value',
                                      this.props.lang,
                                    ) == 'string'
                                    ? Object.translate(
                                        item,
                                        'attributes.main_colour_1.value',
                                        this.props.lang,
                                      )
                                    : Object.translate(
                                        item,
                                        'attributes.main_colour_1.value',
                                        this.props.lang,
                                      ).join(', ')
                                  : '';
                              })
                              .filter((item) => item)
                              .join(', ')}
                          </span>
                        ) : (
                          <span className="choosen-color">
                            {Object.translate(
                              this.state.product,
                              'attributes.main_colour_1.value',
                              this.props.lang,
                            )
                              ? typeof Object.translate(
                                  this.state.product,
                                  'attributes.main_colour_1.value',
                                  this.props.lang,
                                ) == 'string'
                                ? Object.translate(
                                    this.state.product,
                                    'attributes.main_colour_1.value',
                                    this.props.lang,
                                  )
                                : Object.translate(
                                    this.state.product,
                                    'attributes.main_colour_1.value',
                                    this.props.lang,
                                  ).join(', ')
                              : '/'}
                          </span>
                        )}
                      </div>
                      <div className="color-choose">
                        {this.state.product && this.state.product.variations
                          ? this.state.product.variations.map((item, idx) => {
                              if (idx < 5)
                                return (
                                  <Link
                                    to={`/products/${Object.translate(
                                      item,
                                      'alias',
                                      this.props.lang,
                                    )}`}
                                  >
                                    <span>
                                      <img
                                        src={
                                          item.thumbnailExposed
                                            ? API_ENDPOINT.replace(
                                                'testapi',
                                                'api',
                                              ) +
                                              item.thumbnailExposed.thumbnail
                                            : noImage
                                        }
                                      />
                                    </span>
                                  </Link>
                                );
                            })
                          : null}
                      </div>
                    </div>
                    {this.state.product &&
                    this.state.product.groupProducts &&
                    this.state.product.groupProducts.length ? (
                      <div className="color">
                        <div className="color-type">
                          <h6>{'Produkter:'.translate(this.props.lang)} </h6>
                        </div>
                        <div className="color-choose">
                          {this.state.product.groupProducts.map((item, idx) => {
                            if (idx < 5)
                              return (
                                <Link
                                  to={`/products/${Object.translate(
                                    item,
                                    'alias',
                                    this.props.lang,
                                  )}`}
                                >
                                  <span>
                                    <img
                                      src={
                                        item.thumbnailExposed
                                          ? API_ENDPOINT.replace(
                                              'testapi',
                                              'api',
                                            ) + item.thumbnailExposed.thumbnail
                                          : noImage
                                      }
                                    />
                                  </span>
                                </Link>
                              );
                          })}
                        </div>
                      </div>
                    ) : null}

                    <div className="flex-row-custom">
                      <h6>{'GTIN:'.translate(this.props.lang)}</h6>
                      <span>
                        {this.state.product && this.state.product.eanCode
                          ? this.state.product.eanCode
                          : '/'}{' '}
                      </span>
                    </div>
                    <div className="flex-row-custom">
                      <h6>{'Artikelnr:'.translate(this.props.lang)}</h6>
                      <span>
                        {this.state.product ? this.state.product.sku : null}
                      </span>
                    </div>
                    {this.state.product &&
                    this.state.product.productDimensions ? (
                      <div className="flex-row-custom row-dimensions">
                        <h6>{'Välj storlek:'.translate(this.props.lang)}</h6>
                        <select
                          onChange={(e) => {
                            this.props[0].history.push(
                              `/products/${e.target.value}`,
                            );
                          }}
                          value={Object.translate(
                            this.state.product,
                            'alias',
                            this.props.lang,
                          )}
                        >
                          {this.state.product.productDimensions.map(
                            (item, idx) => {
                              if (item.productDimensionValue) {
                                return (
                                  <option
                                    value={Object.translate(
                                      item,
                                      'alias',
                                      this.props.lang,
                                    )}
                                  >
                                    {item.productDimensionValue}
                                  </option>
                                );
                              } else {
                                return (
                                  <option
                                    value={Object.translate(
                                      item,
                                      'alias',
                                      this.props.lang,
                                    )}
                                  >
                                    {item.attributes.overall_width_1}x
                                    {item.attributes.overall_height_1
                                      ? item.attributes.overall_height_1
                                      : item.attributes.overall_length_1}
                                  </option>
                                );
                              }
                            },
                          )}
                        </select>
                      </div>
                    ) : null}

                    {this.state.product && this.state.product.price ? (
                      this.state.product.price.oldPrice &&
                      (this.props.uData && !this.props.uData.creditCardPayment
                        ? this.state.product.price.value
                        : this.state.product.price.value *
                          (1 + this.state.product.price.vat / 100)) !==
                        (this.props.uData && !this.props.uData.creditCardPayment
                          ? this.state.product.price.oldPrice
                          : this.state.product.price.oldPrice *
                            (1 + this.state.product.price.vat / 100)) ? (
                        <div className="flex-row-custom">
                          <h6>{'Pris:'.translate(this.props.lang)}</h6>
                          <span>
                            <span
                              style={{
                                textDecoration: 'line-through',
                                paddingLeft: 0,
                                fontSize: 12,
                              }}
                            >
                              {this.props.uData &&
                              !this.props.uData.creditCardPayment
                                ? this.state.product.price.oldPrice.toFixed(0)
                                : this.state.product.price.oldPrice.toFixed(0) *
                                  (1 + this.state.product.price.vat / 100)}{' '}
                              {this.state.product.price.currency}
                            </span>{' '}
                            <br />
                            <span
                              style={{
                                paddingLeft: 0,
                                color: 'red',
                                fontWeight: 700,
                              }}
                            >
                              {this.props.uData &&
                              !this.props.uData.creditCardPayment
                                ? this.state.product.price.value
                                : this.state.product.price.value *
                                  (1 + this.state.product.price.vat / 100)}{' '}
                              {this.state.product.price.currency}
                            </span>
                          </span>
                        </div>
                      ) : (
                        <div className="flex-row-custom">
                          <h6>{'Pris:'.translate(this.props.lang)}</h6>
                          <span>
                            {this.props.uData &&
                            !this.props.uData.creditCardPayment
                              ? this.state.product.price.value
                              : this.state.product.price.value *
                                (1 + this.state.product.price.vat / 100)}{' '}
                            {this.state.product.price.currency}
                          </span>
                        </div>
                      )
                    ) : null}
                    {this.state.product &&
                    this.state.product.inStock &&
                    this.state.product.inStock > 0 &&
                    this.state.product.inStock >= quantityStep &&
                    this.state.product.price ? (
                      <div className="flex-row-custom">
                        <h6>{'Lager:'.translate(this.props.lang)}</h6>
                        <span>
                          {this.state.product.inStock > 100
                            ? '100+'
                            : this.state.product.inStock}{' '}
                        </span>
                      </div>
                    ) : null}
                    {this.state.product &&
                    this.state.product.inStock < quantityStep &&
                    this.state.product.nextStock &&
                    this.state.product.nextStock.value > 0 &&
                    this.state.product.price ? (
                      <div className="flex-row-custom">
                        <h6>{'Lager:'.translate(this.props.lang)}</h6>
                        <span>
                          {this.state.product.inStock > 100
                            ? '100+'
                            : this.state.product.inStock}{' '}
                        </span>
                      </div>
                    ) : null}

                    {this.state.product &&
                    this.state.product.inStock < 10 &&
                    this.state.product.nextStock &&
                    this.state.product.nextStock.value >= quantityStep &&
                    this.state.product.nextStock.timestamp &&
                    this.state.product.price ? (
                      <p
                        className="quantity-step-info"
                        style={{ fontSize: 14, fontWeight: 600 }}
                      >
                        {'Beräknas åter i lager'.translate(this.props.lang)}{' '}
                        {moment
                          .unix(this.state.product.nextStock.timestamp)
                          .format('YYYY-MM-DD')}
                        <br />
                        {'Lagersaldo efter ny leverans:'.translate(
                          this.props.lang,
                        )}{' '}
                        {this.state.product.nextStock.value > 100
                          ? '100+'
                          : this.state.product.nextStock.value}
                      </p>
                    ) : null}
                  </div>
                  {this.state.product &&
                  this.state.product.price &&
                  this.state.product.price.value &&
                  ((this.state.product.inStock > 0 &&
                    this.state.product.inStock >= quantityStep) ||
                    (this.state.product.inStock < quantityStep &&
                      this.state.product.nextStock &&
                      this.state.product.nextStock.value >= quantityStep &&
                      this.state.product.nextStock.timestamp)) ? (
                    <>
                      {quantityStep > 1 ? (
                        <p className="quantity-step-info">
                          {'Produkten säljs endast i'.translate(
                            this.props.lang,
                          )}{' '}
                          {quantityStep}
                          {'-pack, priset visas per styck'.translate(
                            this.props.lang,
                          )}
                        </p>
                      ) : null}
                      {/* { ((!this.props.uData || (this.props.uData && this.props.uData.creditCardPayment)) && this.state.product.inStock > 0 ) || (this.props.uData && !this.props.uData.creditCardPayment) ? */}
                      <div className="product-quantity">
                        <div>
                          <button
                            className="minus"
                            onClick={() => {
                              if (
                                this.state.quantity -
                                  (quantityStep ? quantityStep : 1) >
                                0
                              ) {
                                this.setState({
                                  quantity:
                                    this.state.quantity -
                                    (quantityStep ? quantityStep : 1),
                                });
                                //  this.props.addToCart(item._id, item.quantity - 1, true, this.get)
                              }
                            }}
                          >
                            -
                          </button>
                          <input
                            type="number"
                            disabled
                            inputmode="numeric"
                            step={quantityStep ? quantityStep : 1}
                            min="0"
                            value={this.state.quantity}
                            onChange={(e) => {
                              // if (parseInt(e.target.value) > 0)
                              //     this.props.addToCart(item._id, parseInt(e.target.value), true, this.get)
                              if (
                                parseInt(e.target.value) > 0 &&
                                (!quantityStep ||
                                  quantityStep == 1 ||
                                  e.target.value % quantityStep == 0)
                              )
                                this.setState({
                                  quantity: e.target.value,
                                });
                            }}
                          />
                          <button
                            className="plus"
                            onClick={() => {
                              let inStock = this.state.product.inStock;
                              if (!inStock || inStock <= quantityStep) {
                                if (
                                  this.state.product.nextStock &&
                                  this.state.product.nextStock.value &&
                                  this.state.product.nextStock.timestamp
                                ) {
                                  inStock = this.state.product.nextStock.value;
                                }
                              }
                              let quantity =
                                this.state.quantity +
                                (quantityStep ? quantityStep : 1);
                              if (quantity > inStock) {
                                return;
                              }
                              this.setState({
                                quantity: quantity,
                              });

                              //  this.props.addToCart(this.state.product._id, item.quantity + 1, true, this.get)
                            }}
                          >
                            +
                          </button>
                        </div>
                        <button
                          onClick={() =>
                            this.props.addToCart(
                              this.state.product._id,
                              this.state.quantity,
                              false,
                              null,
                              true,
                              this.state.product,
                            )
                          }
                        >
                          {'Lägg i varukorg'.translate(this.props.lang)}
                        </button>
                      </div>
                      {/* :
                                            null
                                                } */}
                    </>
                  ) : this.state.product &&
                    this.state.product.price &&
                    this.state.product.price.value &&
                    this.state.product.inStock < 0 &&
                    this.state.product.nextStock &&
                    this.state.product.nextStock.timestamp &&
                    this.state.product.nextStock.value <= 0 ? (
                    <p
                      className="quantity-step-info"
                      style={{ fontSize: 14, fontWeight: 600 }}
                    >
                      {'Tillfälligt slut'.translate(this.props.lang)}
                    </p>
                  ) : this.state.product &&
                    this.state.product.price &&
                    this.state.product.price.value &&
                    this.state.product.categoryChain &&
                    this.state.product.categoryChain[0] &&
                    this.state.product.categoryChain[0].breadcrumb &&
                    this.state.product.categoryChain[0].breadcrumb.en &&
                    this.state.product.categoryChain[0].breadcrumb.en.indexOf(
                      'outdoor',
                    ) !== -1 ? (
                    <p
                      className="quantity-step-info"
                      style={{ fontSize: 14, fontWeight: 600 }}
                    >
                      {'Slut för säsongen'.translate(this.props.lang)}
                    </p>
                  ) : (
                    <Link
                      to="/hitta-aterforsaljare"
                      className="button-transparent"
                    >
                      <div className="button-inner">
                        {'HITTA BUTIK'.translate(this.props.lang)}
                      </div>
                      <div className="button-outer">
                        {'HITTA BUTIK'.translate(this.props.lang)}
                      </div>
                    </Link>
                  )}

                  <div class="accordion-body">
                    <div class="accordion">
                      <div
                        class={
                          this.state.tab === 0
                            ? 'container active'
                            : 'container'
                        }
                      >
                        <div
                          class="label"
                          onClick={() =>
                            this.setState({
                              tab: this.state.tab === 0 ? -1 : 0,
                            })
                          }
                        >
                          {'BESKRIVNING'.translate(this.props.lang)}
                        </div>
                        <div
                          class="content"
                          dangerouslySetInnerHTML={{
                            __html: Object.translate(
                              this.state.product,
                              'description',
                              this.props.lang,
                            ),
                          }}
                        ></div>
                      </div>

                      <hr />

                      {this.state.product &&
                      this.state.product.instructionsForUse ? (
                        <>
                          <div
                            class={
                              this.state.tab === 6
                                ? 'container active'
                                : 'container'
                            }
                          >
                            <div
                              class="label"
                              onClick={() =>
                                this.setState({
                                  tab: this.state.tab === 6 ? -1 : 6,
                                })
                              }
                            >
                              {'INSTRUCTIONS FOR USE'.translate(
                                this.props.lang,
                              )}
                            </div>
                            <div
                              class="content"
                              dangerouslySetInnerHTML={{
                                __html: Object.translate(
                                  this.state.product,
                                  'instructionsForUse',
                                  this.props.lang,
                                ),
                              }}
                            ></div>
                          </div>

                          <hr />
                        </>
                      ) : null}

                      {this.state.product &&
                      this.state.product.groupProducts &&
                      this.state.product.groupProducts[0] &&
                      this.state.product.groupProducts[0].attributes &&
                      Object.keys(
                        this.state.product.groupProducts[0].attributes,
                      ).length ? (
                        <div
                          class={
                            this.state.tab === 1
                              ? 'container active'
                              : 'container'
                          }
                        >
                          <div
                            class="label"
                            onClick={() =>
                              this.setState({
                                tab: this.state.tab === 1 ? -1 : 1,
                              })
                            }
                          >
                            {'MER INFORMATION'.translate(this.props.lang)}
                          </div>
                          <div class="content">
                            {this.state.product.groupProducts.map(
                              (groupProduct, gidx) => {
                                return (
                                  <>
                                    <h6 className="group-product-name">
                                      {groupProduct.productFamily}{' '}
                                      <span>
                                        {Object.translate(
                                          groupProduct,
                                          'name',
                                          this.props.lang,
                                        )}
                                      </span>{' '}
                                    </h6>
                                    <table>
                                      {Object.keys(groupProduct.attributes).map(
                                        (item, idx) => {
                                          if (
                                            groupProduct.attributes[item]
                                              .value &&
                                            groupProduct.attributes[item].value[
                                              this.props.lang
                                            ]
                                          )
                                            return (
                                              <tr>
                                                <td>
                                                  {capitalizeFirstLetter(
                                                    Object.translate(
                                                      groupProduct.attributes[
                                                        item
                                                      ],
                                                      'name',
                                                      this.props.lang,
                                                    ),
                                                  )}
                                                </td>
                                                <td>
                                                  {groupProduct.attributes[item]
                                                    .value
                                                    ? typeof Object.translate(
                                                        groupProduct.attributes[
                                                          item
                                                        ],
                                                        'value',
                                                        this.props.lang,
                                                      ) == 'boolean'
                                                      ? Object.translate(
                                                          groupProduct
                                                            .attributes[item],
                                                          'value',
                                                          this.props.lang,
                                                        )
                                                        ? 'JA'.translate(
                                                            this.props.lang,
                                                          )
                                                        : 'NEJ'.translate(
                                                            this.props.lang,
                                                          )
                                                      : Array.isArray(
                                                          Object.translate(
                                                            groupProduct
                                                              .attributes[item],
                                                            'value',
                                                            this.props.lang,
                                                          ),
                                                        )
                                                      ? Object.translate(
                                                          groupProduct
                                                            .attributes[item],
                                                          'value',
                                                          this.props.lang,
                                                        ).join(', ')
                                                      : Object.translate(
                                                          groupProduct
                                                            .attributes[item],
                                                          'value',
                                                          this.props.lang,
                                                        )
                                                    : null}
                                                </td>
                                              </tr>
                                            );
                                        },
                                      )}
                                    </table>
                                  </>
                                );
                              },
                            )}
                          </div>
                        </div>
                      ) : this.state.product &&
                        this.state.product.attributes &&
                        Object.keys(this.state.product.attributes).length ? (
                        <div
                          class={
                            this.state.tab === 1
                              ? 'container active'
                              : 'container'
                          }
                        >
                          <div
                            class="label"
                            onClick={() =>
                              this.setState({
                                tab: this.state.tab === 1 ? -1 : 1,
                              })
                            }
                          >
                            {'MER INFORMATION'.translate(this.props.lang)}
                          </div>
                          <div class="content">
                            <table>
                              {attrGroups.map((groupName, gidx) => {
                                return (
                                  <>
                                    <h6 className="group-product-name">
                                      {groupName[this.props.lang]}{' '}
                                    </h6>
                                    <table>
                                      {Object.keys(
                                        this.state.product.attributes,
                                      ).map((item, idx) => {
                                        if (
                                          ((!this.state.product.attributes[item]
                                            .group &&
                                            groupName.en == '') ||
                                            (this.state.product.attributes[item]
                                              .group &&
                                              this.state.product.attributes[
                                                item
                                              ].group.en == groupName.en)) &&
                                          this.state.product.attributes[item]
                                            .value &&
                                          this.state.product.attributes[item]
                                            .value[this.props.lang] !==
                                            undefined &&
                                          this.state.product.attributes[item]
                                            .value[this.props.lang] !== null
                                        )
                                          return (
                                            <tr>
                                              <td>
                                                {capitalizeFirstLetter(
                                                  Object.translate(
                                                    this.state.product
                                                      .attributes[item],
                                                    'name',
                                                    this.props.lang,
                                                  ),
                                                )}
                                              </td>
                                              <td>
                                                {this.state.product.attributes[
                                                  item
                                                ].value
                                                  ? typeof Object.translate(
                                                      this.state.product
                                                        .attributes[item],
                                                      'value',
                                                      this.props.lang,
                                                    ) == 'boolean'
                                                    ? Object.translate(
                                                        this.state.product
                                                          .attributes[item],
                                                        'value',
                                                        this.props.lang,
                                                      )
                                                      ? 'JA'.translate(
                                                          this.props.lang,
                                                        )
                                                      : 'NEJ'.translate(
                                                          this.props.lang,
                                                        )
                                                    : Array.isArray(
                                                        Object.translate(
                                                          this.state.product
                                                            .attributes[item],
                                                          'value',
                                                          this.props.lang,
                                                        ),
                                                      )
                                                    ? Object.translate(
                                                        this.state.product
                                                          .attributes[item],
                                                        'value',
                                                        this.props.lang,
                                                      ).join(', ')
                                                    : Object.translate(
                                                        this.state.product
                                                          .attributes[item],
                                                        'value',
                                                        this.props.lang,
                                                      )
                                                  : null}
                                              </td>
                                            </tr>
                                          );
                                      })}
                                    </table>
                                  </>
                                );
                              })}
                            </table>
                          </div>
                        </div>
                      ) : null}

                      <hr />

                      {this.state.product &&
                      this.state.product.measurementPhotosV2 &&
                      this.state.product.measurementPhotosV2.length ? (
                        <div
                          class={
                            this.state.tab === 2
                              ? 'container active'
                              : 'container'
                          }
                        >
                          <div
                            class="label"
                            onClick={() =>
                              this.setState({
                                tab: this.state.tab === 2 ? -1 : 2,
                              })
                            }
                          >
                            {'Måttbilder'.translate(this.props.lang)}
                          </div>
                          <div class="content">
                            <div className="items">
                              {this.state.product.measurementPhotosV2
                                ? this.state.product.measurementPhotosV2.map(
                                    (item, idx) => {
                                      return (
                                        <div>
                                          <a href={item.url} target="_blank">
                                            {item.url.indexOf('.pdf') !== -1 ? (
                                              <Isvg src={pdfIcon} />
                                            ) : (
                                              <img src={item.url} />
                                            )}
                                          </a>
                                        </div>
                                      );
                                    },
                                  )
                                : null}
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.product &&
                      this.state.product.measurementPhotosV2 &&
                      this.state.product.measurementPhotosV2.length ? (
                        <hr />
                      ) : null}
                      {this.state.product &&
                      this.state.product.assemblyInstructionsV2 &&
                      this.state.product.assemblyInstructionsV2.length ? (
                        <div
                          class={
                            this.state.tab === 3
                              ? 'container active'
                              : 'container'
                          }
                        >
                          <div
                            class="label"
                            onClick={() =>
                              this.setState({
                                tab: this.state.tab === 3 ? -1 : 3,
                              })
                            }
                          >
                            {'Monteringsanvisningar'.translate(this.props.lang)}
                          </div>
                          <div class="content">
                            <div className="items">
                              {this.state.product.assemblyInstructionsV2
                                ? this.state.product.assemblyInstructionsV2.map(
                                    (item, idx) => {
                                      return (
                                        <div>
                                          <a href={item.url} target="_blank">
                                            {item.url.indexOf('.pdf') !== -1 ? (
                                              <Isvg src={pdfIcon} />
                                            ) : (
                                              <img src={item.url} />
                                            )}
                                          </a>
                                        </div>
                                      );
                                    },
                                  )
                                : null}
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.product &&
                      this.state.product.assemblyInstructionsV2 &&
                      this.state.product.assemblyInstructionsV2.length ? (
                        <hr />
                      ) : null}

                      {this.state.product &&
                      this.state.product.careAdvice &&
                      this.state.product.careAdvice.length ? (
                        <div
                          class={
                            this.state.tab === 5
                              ? 'container active'
                              : 'container'
                          }
                        >
                          <div
                            class="label"
                            onClick={() =>
                              this.setState({
                                tab: this.state.tab === 5 ? -1 : 5,
                              })
                            }
                          >
                            {'VÅRA SKÖTSELRÅD'.translate(this.props.lang)}
                          </div>
                          <div class="content">
                            <Row>
                              {this.state.product.careAdvice.map(
                                (item, idx) => {
                                  return (
                                    <Col lg="6">
                                      <div
                                        className="care-advice-item"
                                        onClick={() =>
                                          this.setState({ activeIndex: idx })
                                        }
                                      >
                                        <div className="care-advice-item">
                                          <img
                                            src={
                                              API_ENDPOINT.replace(
                                                'testapi',
                                                'api',
                                              ) + item.image
                                            }
                                          />
                                          <h3>
                                            {Object.translate(
                                              item,
                                              'name',
                                              this.props.lang,
                                            )}
                                          </h3>
                                        </div>
                                      </div>
                                    </Col>
                                  );
                                },
                              )}
                            </Row>
                          </div>
                        </div>
                      ) : null}

                      {this.state.product &&
                      this.state.product.careAdvice &&
                      this.state.product.careAdvice.length ? (
                        <hr />
                      ) : null}
                      {this.state.product &&
                      this.state.product.sparePartsV2 &&
                      this.state.product.sparePartsV2.length &&
                      this.props.uData ? (
                        <div
                          class={
                            this.state.tab === 4
                              ? 'container active'
                              : 'container'
                          }
                        >
                          <div
                            class="label"
                            onClick={() =>
                              this.setState({
                                tab: this.state.tab === 4 ? -1 : 4,
                              })
                            }
                          >
                            {'Reservdelar'.translate(this.props.lang)}
                          </div>
                          <div class="content">
                            <div className="items spare-parts-list">
                              {this.state.product.sparePartsV2 ? (
                                <ul>
                                  {this.state.product.sparePartsV2.map(
                                    (item, idx) => {
                                      return (
                                        <li key={idx}>
                                          <span>{item.sku}</span> / {item.label}
                                        </li>
                                      );
                                    },
                                  )}
                                </ul>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.product &&
                      this.state.product.sparePartsV2 &&
                      this.state.product.sparePartsV2.length &&
                      this.props.uData ? (
                        <hr />
                      ) : null}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="similar-articles">
          {this.state.similarProducts && this.state.similarProducts.length ? (
            <Container fluid>
              <Row>
                <Col lg="12">
                  <div className="similar-articles-title">
                    {this.state.product &&
                    this.state.product.breadcrumb &&
                    this.state.product.breadcrumb[0] &&
                    this.state.product.breadcrumb[0].en &&
                    this.state.product.breadcrumb[0].en.indexOf('/indoor') !==
                      -1 ? (
                      <h2>{'MATCHA MED'.translate(this.props.lang)}</h2>
                    ) : (
                      <h2>
                        {'RELATERADE PRODUKTER'.translate(this.props.lang)}
                      </h2>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                {this.state.similarProducts.length < 4 ? (
                  this.state.similarProducts.map((item, idx) => {
                    if (
                      typeof window !== 'undefined' &&
                      String(localStorage.getItem('guestToken')).indexOf(
                        'retrack-',
                      ) === -1
                    ) {
                      if (
                        !this.props.uData &&
                        item.price &&
                        this.props[0].location.pathname.indexOf('/sale') == -1
                      ) {
                        item.price = null;
                      }
                    }
                    return (
                      <Col lg="3">
                        <Article
                          wishlistDict={this.props.wishlistDict}
                          addToWishlist={this.props.addToWishlist}
                          uData={this.props.uData}
                          lang={this.props.lang}
                          {...item}
                        />
                      </Col>
                    );
                  })
                ) : (
                  <Col lg="12">
                    <div className="products-slider">
                      <Slider {...settingsProductsSlider}>
                        {this.state.similarProducts.map((item, idx) => {
                          if (
                            typeof window !== 'undefined' &&
                            String(localStorage.getItem('guestToken')).indexOf(
                              'retrack-',
                            ) === -1
                          ) {
                            if (
                              !this.props.uData &&
                              item.price &&
                              this.props[0].location.pathname.indexOf(
                                '/sale',
                              ) == -1
                            ) {
                              item.price = null;
                            }
                          }
                          return (
                            <Article
                              wishlistDict={this.props.wishlistDict}
                              addToWishlist={this.props.addToWishlist}
                              uData={this.props.uData}
                              lang={this.props.lang}
                              {...item}
                            />
                          );
                        })}
                      </Slider>
                    </div>
                  </Col>
                )}
              </Row>
            </Container>
          ) : null}
          {this.state.linkedProducts && this.state.linkedProducts.length ? (
            <Container fluid>
              <Row>
                <Col lg="12">
                  <div className="similar-articles-title">
                    <h2>{'LIKNANDE PRODUKTER'.translate(this.props.lang)}</h2>
                  </div>
                </Col>
              </Row>
              <Row>
                {this.state.linkedProducts.length < 4 ? (
                  this.state.linkedProducts.map((item) => {
                    if (
                      typeof window !== 'undefined' &&
                      String(localStorage.getItem('guestToken')).indexOf(
                        'retrack-',
                      ) === -1
                    ) {
                      if (
                        !this.props.uData &&
                        item.price &&
                        this.props[0].location.pathname.indexOf('/sale') == -1
                      ) {
                        item.price = null;
                      }
                    }
                    return (
                      <Col lg="3">
                        <Article
                          wishlistDict={this.props.wishlistDict}
                          addToWishlist={this.props.addToWishlist}
                          uData={this.props.uData}
                          lang={this.props.lang}
                          {...item}
                        />
                      </Col>
                    );
                  })
                ) : (
                  <Col lg="12">
                    <div className="products-slider">
                      <Slider {...settingsProductsSlider}>
                        {[
                          ...this.state.linkedProducts,
                          ...this.state.linkedProducts,
                          ...this.state.linkedProducts,
                          ...this.state.linkedProducts,
                        ].map((item, idx) => {
                          if (
                            typeof window !== 'undefined' &&
                            String(localStorage.getItem('guestToken')).indexOf(
                              'retrack-',
                            ) === -1
                          ) {
                            if (
                              !this.props.uData &&
                              item.price &&
                              this.props[0].location.pathname.indexOf(
                                '/sale',
                              ) == -1
                            ) {
                              item.price = null;
                            }
                          }
                          return (
                            <Article
                              wishlistDict={this.props.wishlistDict}
                              addToWishlist={this.props.addToWishlist}
                              uData={this.props.uData}
                              lang={this.props.lang}
                              {...item}
                            />
                          );
                        })}
                      </Slider>
                    </div>
                  </Col>
                )}
              </Row>
            </Container>
          ) : null}
        </section>

        <h2 className="section-3-title">
          {Object.translate(
            this.props,
            'homeData.section[3].title',
            this.props.lang,
          )}
        </h2>
        <div className="cta">
          {typeof window !== 'undefined' && window.innerWidth < 768 ? (
            <img
              src={
                API_ENDPOINT +
                Object.get(this.props, 'homeData.section[3].mobileImage')
              }
            />
          ) : (
            <img
              src={
                API_ENDPOINT +
                Object.get(this.props, 'homeData.section[3].image')
              }
            />
          )}
          <div className="cta-content">
            <h3>
              <span>
                {Object.translate(
                  this.props,
                  'homeData.section[3].text',
                  this.props.lang,
                )}
              </span>
            </h3>
            <Link
              to={Object.translate(
                this.props,
                'homeData.section[3].button.link',
                this.props.lang,
              )}
              className="button-clasic"
            >
              <div className="button-inner">
                {Object.translate(
                  this.props,
                  'homeData.section[3].button.text',
                  this.props.lang,
                )}
              </div>
              <div className="button-outer">
                {Object.translate(
                  this.props,
                  'homeData.section[3].button.text',
                  this.props.lang,
                )}
              </div>
            </Link>
          </div>
        </div>

        {this.state.activeIndex !== null ? (
          <div className="care-advice-carousel">
            <Carousel
              activeIndex={this.state.activeIndex}
              next={this.next}
              previous={this.previous}
              autoPlay={false}
              interval={3600 * 1000}
            >
              {this.state.product.careAdvice.map((item, idx) => {
                return (
                  <CarouselItem
                    key={idx}
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                  >
                    <div>
                      <div className="care-advice-box">
                        <div className="care-advice-media">
                          <img
                            src={
                              API_ENDPOINT.replace('testapi', 'api') +
                              item.image
                            }
                            style={{ height: item.video ? '70%' : '100%' }}
                          />
                          {item.video ? (
                            <video muted controls>
                              <soruce
                                src={
                                  API_ENDPOINT.replace('testapi', 'api') +
                                  item.video
                                }
                              ></soruce>
                            </video>
                          ) : null}
                        </div>
                        <div className="care-advice-detail">
                          <div className="care-advice-head">
                            <Isvg src={miniLogo} />
                            {'VÅRA SKÖTSELRÅD'.translate(this.props.lang)}
                          </div>
                          <div className="care-advice-text">
                            <h6>
                              {Object.translate(item, 'name', this.props.lang)}
                            </h6>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: Object.translate(
                                  item,
                                  'text',
                                  this.props.lang,
                                ).replace(/\n/g, '<br/>'),
                              }}
                            ></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </CarouselItem>
                );
              })}
              <CarouselControl
                direction="prev"
                directionText="Previous"
                onClickHandler={this.previous}
              />
              <CarouselControl
                direction="next"
                directionText="Next"
                onClickHandler={this.next}
              />
            </Carousel>
            <button
              className="care-advice-carousel-close"
              onClick={() => this.setState({ activeIndex: null })}
            >
              &times;
            </button>
          </div>
        ) : null}
      </div>
    );
  }
}

export default Page(ArticleDetailPage);
